import React, { useState } from 'react';
import { find, isEmpty, orderBy } from 'lodash';
import { Button, Typography } from 'antd';
import { useAppSelector } from 'hooks/useReduxHook';
import { BsDot } from 'react-icons/bs';
import { primary, lightred } from 'constants/theme.constants';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { getMember } from 'store/projects';
import {
  MemberT,
  ProjectStagesList,
  ProjectState,
  ProposedTimelineStatus
} from 'store/projects/types';
import { AOIDetail, AOIStausType } from 'store/aoi/types';
import AoiItemCard from './AoiItemCard';
import AOIRouteLoadingCard from './AOIRouteLoadingCard';

const { Text } = Typography;

interface AOIListPropsType {
  isShowGenerateButton: boolean;
  isDisableGenerateButton: boolean;
  isRequestingGenerateAOI: boolean;
  aoiLists: AOIDetail[] | [];
  selectedAoiIds: string[];
  editedAOIData: AOIDetail | null;
  selectedAOIData: AOIDetail | null;
  handleGenerateAoiData: () => void;
  handleSelectedAOI: (data: AOIDetail | null) => void;
  handleShowHideSelectedAoiIds: (fileId: string) => void;
  handleEditAOI: (data: AOIDetail | null) => void;
}

const AOIList = ({
  isDisableGenerateButton,
  isShowGenerateButton,
  isRequestingGenerateAOI,
  aoiLists,
  editedAOIData,
  selectedAOIData,
  selectedAoiIds,
  handleGenerateAoiData,
  handleShowHideSelectedAoiIds,
  handleEditAOI,
  handleSelectedAOI
}: AOIListPropsType) => {
  const [isViewCommentModal, setViewCommentModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);

  const isApprovedSatelliteConfirm = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteConfirm,
      status: ProposedTimelineStatus.Approved
    })
  );

  const handleChangeAOI = (id: string) => {
    const selectedAoi = aoiLists.find((aoi: any) => aoi.id === id);
    handleSelectedAOI(selectedAoi || null);
  };

  const wmApprovedAOI: AOIDetail | undefined = find(aoiLists, ['status', AOIStausType.wmapproved]);

  return (
    <>
      <div className="aoi-main">
        {isShowGenerateButton && (
          <div className="generate-aoi-btn-div">
            <IsViewerWrapper>
              <Button
                className="generate-aoi-btn"
                type="primary"
                data-testid="generateaoi"
                ghost
                disabled={isDisableGenerateButton}
                onClick={handleGenerateAoiData}
                loading={isRequestingGenerateAOI}>
                Generate AOI
              </Button>
            </IsViewerWrapper>
          </div>
        )}
        <div className="aoi-list">
          {!isEmpty(aoiLists) && (
            <Text className="generate-title" key="aoi-list">
              Generated AOI<Text>*</Text>
            </Text>
          )}
          <div className="aoi-list-items" key="aoi-list-comp">
            {!editedAOIData ? (
              <>
                {wmApprovedAOI && (
                  <AoiItemCard
                    isEditAOI={false}
                    aoiDetail={wmApprovedAOI}
                    selectedAoiIds={selectedAoiIds}
                    selectedAoi={selectedAOIData}
                    handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                    handleEditAOI={handleEditAOI}
                    handleChangeAOI={handleChangeAOI}
                  />
                )}
                {wmApprovedAOI && wmApprovedAOI.updated_by && wmApprovedAOI.updated_at !== null && (
                  <div className="approve-dwnld">
                    <BsDot size={16} color={primary} />
                    <Text className="approved">{`Approved by ${
                      getMember(wmApprovedAOI.updated_by!).label
                    } for High
                             Res Satellite Image Purchase`}</Text>
                  </div>
                )}
                {orderBy(aoiLists, ['requested_at'], ['desc'])
                  .filter((a) => a.status !== AOIStausType.wmapproved)
                  .map((item: AOIDetail, i: number) => {
                    const updatedbyUser: MemberT | null = item.updated_by
                      ? getMember(item.updated_by)
                      : null;

                    return (
                      <>
                        {item.status !== AOIStausType.requested &&
                          item.status !== AOIStausType.failed && (
                            <AoiItemCard
                              key={item.id}
                              isEditAOI={false}
                              aoiDetail={item}
                              selectedAoiIds={selectedAoiIds}
                              selectedAoi={selectedAOIData}
                              handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                              handleEditAOI={handleEditAOI}
                              handleChangeAOI={handleChangeAOI}
                            />
                          )}
                        {(item.status === AOIStausType.requested ||
                          item.status === AOIStausType.failed) && (
                          <AOIRouteLoadingCard
                            title="AOI"
                            key={`${item.id}-${item.comment}-${i}`}
                            aoiDetail={item}
                          />
                        )}
                        {updatedbyUser &&
                          item.updated_at !== null &&
                          item.status === AOIStausType.sentforapproval && (
                            <div key={item.status} className="approve-dwnld">
                              <BsDot size={16} color={primary} />
                              <Text className="approved">
                                Sent by {updatedbyUser.label} for approval
                              </Text>
                            </div>
                          )}
                        {updatedbyUser &&
                          item.updated_at !== null &&
                          !isApprovedSatelliteConfirm &&
                          item.status === AOIStausType.pmapproved && (
                            <div
                              key={`${item.status}-${item.status}-${i + 20}`}
                              className="approve-dwnld"
                              style={{ justifyContent: 'flex-start' }}>
                              <BsDot size={16} color={primary} />
                              <Text className="approved">{`Approved by ${updatedbyUser.label}`}</Text>
                            </div>
                          )}
                        {updatedbyUser &&
                          item.updated_at !== null &&
                          item.status === AOIStausType.declined && (
                            <div key={`${item.status}-${i + 1}`} className="declined-aoi">
                              <div>
                                <BsDot size={16} color={lightred} />
                                <Text className="declined">{`Declined by ${updatedbyUser.label}`}</Text>
                              </div>
                              <div
                                onClick={() => {
                                  setViewCommentModal(true);
                                  setComment(item.comment || '');
                                }}>
                                View comments
                              </div>
                            </div>
                          )}
                        {item.status === AOIStausType.failed && (
                          <div
                            key={`${item.status}-${i + 10}`}
                            className="approve-dwnld"
                            style={{ justifyContent: 'flex-start' }}>
                            <BsDot size={16} color={lightred} />
                            <Text className="declined" style={{ color: lightred, width: '80%' }}>
                              AOI Generation Failed
                            </Text>
                          </div>
                        )}
                      </>
                    );
                  })}
              </>
            ) : (
              <AoiItemCard
                isEditAOI
                aoiDetail={editedAOIData}
                selectedAoiIds={selectedAoiIds}
                selectedAoi={selectedAOIData}
                handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                handleEditAOI={handleEditAOI}
                handleChangeAOI={handleChangeAOI}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isViewCommentModal}
        width={523}
        isClosable={false}
        isCancelButton={false}
        okbuttonText="Okay"
        title=""
        description="Decline AOI Comment"
        subDescription={comment}
        handleClickOkButton={() => setViewCommentModal(false)}
        handleClickCancelButton={() => setViewCommentModal(false)}
      />
    </>
  );
};

export default AOIList;
