import { keys } from 'lodash';
import { black } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { CategoryColor, RepositoryFile } from 'store/repositoryfile/types';
import { getAOILayersGroupByCategory } from 'store/repositoryfile';
import { LulcLowHighrResUrl } from 'utils/apiUrls';
import TileLayer from './TileLayer';

interface RenderLowResLayerProps {
  vectorLayerCategories: Array<string>;
}

const RenderLowResLayer = ({ vectorLayerCategories }: RenderLowResLayerProps) => {
  const layerCategory = useAppSelector((state) => getAOILayersGroupByCategory(state));

  return keys(layerCategory)
    .sort()
    .map((category: string) => {
      const files = layerCategory[category];
      return files.map((data: RepositoryFile) => {
        if (vectorLayerCategories.includes(data.category) && data.wms_layer_name !== null) {
          return (
            <TileLayer
              key={`low-${data.id}`}
              uniqueName={`${data.wms_layer_name}-${data.id}`}
              layerUrl={LulcLowHighrResUrl(data.wms_layer_name!)}
              layercolor={CategoryColor[`${data.category}`] || black}
            />
          );
        }
        return true;
      });
    });
};

export default RenderLowResLayer;
