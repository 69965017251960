import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { useAppSelector } from 'hooks/useReduxHook';
import { hybridUrl, roadmapUrl, satelliteUrl } from 'utils/apiUrls';
import { Maptype } from 'types/common.types';
import { ProjectState } from 'store/projects/types';
import { LatLongNumberType } from 'types/aoi.types';
import MapControls from './MapControls';
import { MapProvider } from './MapContext';
import './styles.less';

interface MapComponentProps {
  isRulerMeasurement: boolean;
  handleChangeRulerMeasurement: () => void;
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  isWorkspace?: boolean;
  children?: React.ReactNode;
}

const MapComponent = ({
  children,
  isRulerMeasurement,
  isWorkspace,
  latlngA,
  latlngB,
  handleChangeRulerMeasurement
}: MapComponentProps) => {
  const mapElement = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null | undefined>(null);
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const [mapLayerType, setMapLayerType] = useState<Maptype>(Maptype.satellite);

  const googleMapsLayer = new TileLayer({
    source: new XYZ({
      url: !isWorkspace ? hybridUrl : satelliteUrl
    })
  });

  useEffect(() => {
    if (mapElement.current && !mapRef.current) {
      mapRef.current = new Map({
        target: mapElement.current,
        layers: [googleMapsLayer],
        view: new View({
          center:
            selectedProject && selectedProject.start_point
              ? fromLonLat([selectedProject.start_point.lng, selectedProject.start_point.lat])
              : fromLonLat([78.0, 21.0]),
          zoom: 5
        }),
        controls: []
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.setTarget(undefined);
      }
    };
  }, [isWorkspace]);

  useEffect(() => {
    if (mapRef.current) {
      let sathyUrl: string = mapLayerType === Maptype.satellite ? hybridUrl : roadmapUrl;

      if (isWorkspace) {
        sathyUrl = mapLayerType === Maptype.satellite ? satelliteUrl : roadmapUrl;
      }

      const newLayer = new TileLayer({
        source: new XYZ({
          url: sathyUrl
        })
      });

      mapRef.current.getLayers().setAt(0, newLayer);
    }
  }, [mapLayerType, mapRef, isWorkspace]);

  const handleMapType = (layer: Maptype) => {
    setMapLayerType(layer);
  };

  return (
    <div className="workspace-map-container">
      <div ref={mapElement} style={{ width: '100%', height: '100%' }} />
      {mapRef.current && <MapProvider map={mapRef.current}>{children}</MapProvider>}
      <MapControls
        map={mapRef.current}
        isWorkspace
        isShowRecenter
        latlngA={latlngA}
        latlngB={latlngB}
        mapType={mapLayerType}
        handleMapType={handleMapType}
        isRulerMeasurement={isRulerMeasurement}
        handleChangeRulerMeasurement={handleChangeRulerMeasurement}
      />
    </div>
  );
};

MapComponent.defaultProps = {
  children: null,
  isWorkspace: true
};

export default MapComponent;
