import { Button, Typography } from 'antd';
import { isEmpty, orderBy } from 'lodash';
import { BsDot } from 'react-icons/bs';
import { red } from 'constants/theme.constants';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import RouteItemCard from 'components/RouteSidebar/RouteItemCard';
import { useAppSelector } from 'hooks/useReduxHook';
import { RouteDetail, RouteState } from 'store/route/types';
import { AOIStausType } from 'store/aoi/types';
import AOIRouteLoadingCard from './AOIRouteLoadingCard';

const { Text } = Typography;

interface RouteListsPropsType {
  editedRouteData: RouteDetail | null;
  selectedRoutesIds: string[];
  selectedRouteData: RouteDetail | null;
  handleShowHideRoute: (fileId: string) => void;
  handleGenerateRoute: () => void;
  handleEditRoute: (route: RouteDetail) => void;
  handleSelectRouteDetail: (route: RouteDetail) => void;
}

const RouteLists = ({
  selectedRouteData,
  editedRouteData,
  selectedRoutesIds,
  handleShowHideRoute,
  handleEditRoute,
  handleSelectRouteDetail,
  handleGenerateRoute
}: RouteListsPropsType) => {
  const { routeList }: RouteState = useAppSelector((state) => state.route);
  return (
    <div className="aoi-main">
      <div className="generate-aoi-btn-div">
        <IsViewerWrapper>
          <Button
            data-testid="generateroute"
            className="generate-aoi-btn"
            type="primary"
            ghost
            onClick={handleGenerateRoute}>
            Generate Route
          </Button>
        </IsViewerWrapper>
      </div>
      <div className="aoi-list">
        {!isEmpty(routeList) && (
          <Text className="generate-title" key="route-generation">
            Routes
          </Text>
        )}
        <div className="aoi-list-items" key="route-list-comp">
          {!isEmpty(routeList) &&
            !editedRouteData &&
            orderBy(routeList, ['requested_at'], ['desc']).map((route: RouteDetail, i: number) => {
              return (
                <>
                  {route.status !== AOIStausType.requested &&
                    route.status !== AOIStausType.failed && (
                      <RouteItemCard
                        key={route.id}
                        isEditRoute={false}
                        routeDetail={route}
                        selectedRoutesIds={selectedRoutesIds}
                        selectedRouteData={selectedRouteData}
                        handleShowHideRoute={handleShowHideRoute}
                        handleEditRoute={handleEditRoute}
                        handleSelectRouteDetail={handleSelectRouteDetail}
                      />
                    )}
                  {(route.status === AOIStausType.requested ||
                    route.status === AOIStausType.failed) && (
                    <AOIRouteLoadingCard
                      title="Route"
                      key={`${route.id}-${i}`}
                      aoiDetail={{
                        ...route,
                        parent_route_id: null
                      }}
                    />
                  )}
                  {route.status === AOIStausType.failed && (
                    <div
                      key={route.status}
                      className="approve-dwnld"
                      style={{ justifyContent: 'flex-start' }}>
                      <BsDot size={16} color={red} />
                      <Text className="declined">Route Generation Failed</Text>
                    </div>
                  )}
                </>
              );
            })}
          {editedRouteData && (
            <RouteItemCard
              isEditRoute
              key={editedRouteData.id}
              routeDetail={editedRouteData}
              selectedRoutesIds={selectedRoutesIds}
              selectedRouteData={selectedRouteData}
              handleShowHideRoute={handleShowHideRoute}
              handleEditRoute={handleEditRoute}
              handleSelectRouteDetail={handleSelectRouteDetail}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RouteLists;
