import { Card, Col, Dropdown, Menu, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { LineString } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { getLength } from 'ol/sphere';
import EyeCloseIconV1 from 'assets/svg/EyeCloseIconV1';
import EyeOpenIconV1 from 'assets/svg/EyeOpenIconV1';
import VerticalThreeDotIcon from 'assets/svg/VerticalThreeDotIcon';
import { white } from 'constants/theme.constants';
import { ACCESS_TOKEN, aoiBackColors } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { RouteDetail } from 'store/route/types';
import { ProjectState } from 'store/projects/types';
import { getMember } from 'store/projects';
import { UserPermission } from 'store/users/types';
import { geoJsonTOKmlDownloadFile, geoJsonTOKmlTowerDownloadFile } from 'utils/util';
import './styles.less';
import { getAsyncStorageValue } from 'utils/localStorage';
import { downloadRouteDetails } from 'store/route/actions';

interface RouteListProps {
  routeDetail: RouteDetail;
  selectedRouteData: RouteDetail | null;
  isEditRoute: boolean;
  selectedRoutesIds: string[];
  handleShowHideRoute: (fileId: string) => void;
  handleEditRoute: (route: RouteDetail) => void;
  handleSelectRouteDetail: (route: RouteDetail) => void;
}

const { Text } = Typography;

const RouteItemCard = ({
  routeDetail,
  selectedRouteData,
  handleShowHideRoute,
  handleEditRoute,
  handleSelectRouteDetail,
  selectedRoutesIds,
  isEditRoute
}: RouteListProps) => {
  const dispatch = useAppDispatch();
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const requestedUser = routeDetail.requested_by ? getMember(routeDetail.requested_by) : null;

  const handleClickMenu = async ({ key }: any) => {
    if (key === 'edit') {
      handleEditRoute(routeDetail);
    } else if (key === 'download') {
      await geoJsonTOKmlDownloadFile(routeDetail, routeDetail.index, 'Route');
      if (!isEmpty(routeDetail.towers)) {
        await geoJsonTOKmlTowerDownloadFile(routeDetail, routeDetail.index, 'Route-Tower');
      }
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(downloadRouteDetails(routeDetail.id, token));
      });
    }
  };

  return (
    <Card
      className="route-card"
      style={{
        border: `1px solid ${routeDetail.color}`,
        backgroundColor:
          selectedRouteData && selectedRouteData.id === routeDetail.id
            ? `${aoiBackColors[`${routeDetail.color}`]}`
            : white,
        opacity: 0.7
      }}
      data-testid="route-item-card">
      <div className="inr-content-col" style={{ backgroundColor: `${routeDetail.color}` }} />
      <Row className="inner-card-content" data-testid="route-item-card">
        <Col
          span={16}
          className="inner-card-content-col"
          onClick={() => handleSelectRouteDetail(routeDetail)}>
          <div>
            <Text className="aoi-title">Route {routeDetail.index} </Text>
            <Text>
              (
              {routeDetail.geometry
                ? `${(
                    getLength(
                      new LineString(
                        routeDetail.geometry.coordinates.map((point: any) => fromLonLat(point))
                      )
                    ) / 1e3
                  ).toFixed(2)} km`
                : 0}
              )
            </Text>
          </div>
          {requestedUser && (
            <Text className="upload-name">
              {routeDetail.parent_route_id !== null ? 'Edited by' : 'Generated by'}
              <Text> {requestedUser?.label}</Text>
            </Text>
          )}
        </Col>
        <Col span={6} className="inner-card-content-icon">
          <div>
            <Text
              data-testid="route-item-card-show-hide"
              onClick={() => (isEditRoute ? () => {} : handleShowHideRoute(routeDetail.id))}>
              {selectedRoutesIds.includes(routeDetail.id) ? (
                <EyeOpenIconV1 height={16} width={14} />
              ) : (
                <EyeCloseIconV1 height={16} width={14} />
              )}
            </Text>
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu onClick={handleClickMenu}>
                  <Menu.Item
                    key="edit"
                    data-testid="route-item-card-edit"
                    disabled={selectedProject?.user_permission === UserPermission.Viewer}>
                    Edit
                  </Menu.Item>
                  <Menu.Item key="download">Download</Menu.Item>
                </Menu>
              }
              trigger={['click']}
              overlayClassName="maplayer-dropdown">
              <VerticalThreeDotIcon height={16} width={6} />
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default RouteItemCard;
