import { createSlice } from '@reduxjs/toolkit';
import { assign, find } from 'lodash';
import { RepositoryFileState } from './types';

const initialState: RepositoryFileState = {
  isRequestingFiles: false,
  repositoryFiles: [],
  isRequestingEditRepoFile: false,
  isRequestingAddRepoFile: false,
  isRequestingFileMapAccess: false,
  isRequestingCategory: false,
  isRequestingDownloadFile: false,
  isRequestingDeleteFile: false,
  categories: [],
  isRequestingGeoserverLayerStyles: false,
  geoserverLayerStyles: []
};

export const repofileSlice = createSlice({
  name: 'repofiles',
  initialState,
  reducers: {
    setRequestingFiles: (state, action) => {
      state.isRequestingFiles = action.payload;
    },
    setFiles: (state, action) => {
      state.repositoryFiles = action.payload;
    },
    setEditRequestRepositoryFile: (state, action) => {
      state.isRequestingEditRepoFile = action.payload;
    },
    setAddRequestingRepositoryFile: (state, action) => {
      state.isRequestingAddRepoFile = action.payload;
    },
    setRequestingFileMapAccess: (state, action) => {
      state.isRequestingFileMapAccess = action.payload;
    },
    setRequestingCategories: (state, action) => {
      state.isRequestingCategory = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setRequestingDownloadFile: (state, action) => {
      state.isRequestingDownloadFile = action.payload;
    },
    setDeleteRequestingRepositoryFile: (state, action) => {
      state.isRequestingDeleteFile = action.payload;
    },
    setRequestingGeoserverLayerStyles: (state, action) => {
      state.isRequestingGeoserverLayerStyles = action.payload;
    },
    setGeoserverLayerStyles: (state, action) => {
      state.geoserverLayerStyles = action.payload;
    },
    setUpdateLayerWeightBuffer: (state, action) => {
      const existingLayers = state.repositoryFiles;
      const updateFiles = action.payload.files;
      // eslint-disable-next-line
      for (const layer of existingLayers) {
        const updateFile = find(updateFiles, {
          id: layer.id
        });
        if (updateFile) {
          assign(layer, {
            ...updateFile,
            weight:
              action.payload?.weight || action.payload?.weight === 0
                ? action.payload?.weight
                : layer.weight,
            buffer:
              action.payload?.buffer || action.payload?.buffer === 0
                ? action.payload?.buffer
                : Number(layer.buffer)
          });
        }
      }
    },
    setUpdateAllLayersWeightBuffer: (state, action) => {
      const existingLayers = state.repositoryFiles;
      const aoiRouteLayers = action.payload.layers;
      // eslint-disable-next-line
      for (const layer of existingLayers) {
        const updateFile = find(aoiRouteLayers, {
          file_id: layer.id
        });

        if (updateFile) {
          assign(layer, {
            ...updateFile,
            weight: updateFile.weight,
            buffer: updateFile.buffer
          });
        }
      }
    },
    clearRepofiles: () => {
      return initialState;
    }
  }
});
