import React from 'react';
import { Table, Typography } from 'antd';
import { sortBy } from 'lodash';
import { GenerateAoiPayload } from 'store/aoi/types';

const { Text } = Typography;

interface AOIWeightBufferDetailPropsType {
  vectorData: GenerateAoiPayload[];
  title: string;
}

const AOIWeightBufferDetail = ({ title, vectorData }: AOIWeightBufferDetailPropsType) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'file_cat',
      key: 'file_cat'
    },
    {
      title: 'Weights (0-100)',
      dataIndex: 'weight',
      key: 'weight'
    }
  ];
  return (
    <div>
      <div className="aoi_card">
        <Text className="aoi_card_title">{title}</Text>
        <Table
          scroll={{ y: '200px' }}
          className="aoi-card-table"
          dataSource={sortBy(vectorData, ['file_cat'])}
          pagination={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default AOIWeightBufferDetail;
