import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import Down from 'assets/svg/DownAnalytics.svg';
// import Download from 'assets/svg/DownloadAnalytics.svg';
import { useAppSelector } from 'hooks/useReduxHook';
import { Route } from 'store/route/types';
import {
  computeRouteData,
  getDropDownOptions,
  getSelectedRoute,
  getGeneratedRoute
} from 'store/route/index';

interface RouteData {
  id: string;
  length: number;
  linearCounts: Array<{ file_cat_name: string; count: number }>;
  areaCrossStats: Array<{ file_cat_name: string; area_km_sq: number; unit_cost: number }>;
  towerTypes: string[];
}

const RouteTab: React.FC = () => {
  const routeList = useAppSelector(getGeneratedRoute);
  const [selectedRouteID, setSelectedRouteID] = useState<string[]>([]);
  const selectedRouteList = useAppSelector((state) => getSelectedRoute(state, selectedRouteID));
  const dropDownOptions = useAppSelector((state) => getDropDownOptions(state, selectedRouteID));
  const [routeData, setRouteData] = useState<RouteData[]>([]);

  useEffect(() => {
    const computedData = computeRouteData(routeList);
    setRouteData(computedData);
    setSelectedRouteID(routeList.slice(0, 3).map((route: Route) => route.id));
  }, [routeList]);

  // const handleDownload = (routeId: string) => {
  //   getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
  //     dispatch(downloadRouteDetails(routeId, token));
  //   });
  // };

  const allTowerTypes = Array.from(new Set(routeData.flatMap((r) => r.towerTypes)));
  const allLinearCounts = Array.from(
    new Set(routeData.flatMap((r) => r.linearCounts.map((line) => line.file_cat_name)))
  );
  const allAreaCrossStats = Array.from(
    new Set(routeData.flatMap((r) => r.areaCrossStats.map((stat) => stat.file_cat_name)))
  );

  const dynamicHeaders = {
    totalTowers: 'Total Towers',
    angularTowers: 'Angular Towers',
    totalHardCost: 'Total Tower Cost (INR)',
    totalRoWCost: 'Total RoW Cost',
    linearCounts: allLinearCounts,
    areaCrossStats: allAreaCrossStats
  };

  function onDropDownHandle(event: any, index: number) {
    if (index !== -1) {
      const updatedRouteIDs = [...selectedRouteID];
      updatedRouteIDs[index] = event.key;
      setSelectedRouteID(updatedRouteIDs);
    }
  }

  if (!routeList || routeList.length === 0) {
    return <div className="no_data">No Data Available</div>;
  }

  const formatIndianNumber = (number: number): string => {
    return new Intl.NumberFormat('en-IN').format(number);
  };

  const renderNumber = (value: number | null | undefined): string => {
    return value !== null && value !== undefined ? formatIndianNumber(value) : '-';
  };
  return (
    <div className="routes-container">
      <div>
        <div>
          <div>Compare Routes</div>
          <div>Length of Route (km)</div>
          <div>Total Route Cost</div>
          <div>Total Tower Cost</div>
          <div>Total RoW Cost</div>
        </div>
        {selectedRouteList.map((route, index) => (
          <div key={route.id}>
            <div className="analyticTitleName">
              Route {route.index}
              {dropDownOptions && dropDownOptions.length > 0 && (
                <span>
                  <Dropdown
                    overlay={
                      <Menu>
                        {dropDownOptions.map((option: { label: string; value: string }) => (
                          <Menu.Item key={option.value} onClick={(e) => onDropDownHandle(e, index)}>
                            {option.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={['click']}>
                    <span style={{ fontWeight: 600 }}>
                      <img src={Down} alt="Down Analytics" style={{ marginLeft: 10 }} />
                    </span>
                  </Dropdown>
                </span>
              )}
              {/* <span>
                <button
                  type="button"
                  onClick={() => handleDownload(route.id)}
                  style={{
                    background: 'none',
                    border: 'none',
                    paddingLeft: '10px',
                    cursor: 'pointer'
                  }}
                  aria-label="Download CSV">
                  <img src={Download} alt="Download" style={{ width: '16px' }} />
                </button>
              </span> */}
            </div>
            <div>
              {routeData.find((r) => r.id === route.id)?.length
                ? (routeData.find((r) => r.id === route.id)!.length / 1000).toFixed(2)
                : 'N/A'}
            </div>
            <div>
              {formatIndianNumber((route.total_tower_cost ?? 0) + (route.total_row_cost ?? 0))}
            </div>
            <div>{renderNumber(route.total_tower_cost) || '-'}</div>
            <div>{renderNumber(route.total_row_cost) || '-'}</div>
          </div>
        ))}
      </div>
      <div style={{ maxHeight: 'calc(100vh - 368px)' }}>
        <div>
          <div style={{ backgroundColor: '#ebebeb', fontWeight: '600' }}>
            {dynamicHeaders.totalTowers}
          </div>
          {/* <div>{dynamicHeaders.angularTowers}</div> */}
          <div className="analyticSubTitleName">Towers Count</div>
          {[...allTowerTypes].sort().map((type, index) => (
            <div key={index}>Tower Type {type}</div>
          ))}
          <div className="highlighted">{dynamicHeaders.totalHardCost}</div>
          <div className="analyticSubTitleName">Linear Crossing Stats (Count)</div>
          {dynamicHeaders.linearCounts.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
          <div className="analyticSubTitleName">RoW Area Crossing (sq km)</div>
          {dynamicHeaders.areaCrossStats.map((stat, index) => (
            <div key={index}>{stat}</div>
          ))}
          <div className="highlighted">{dynamicHeaders.totalRoWCost}</div>
        </div>
        {selectedRouteList.slice(0, 3).map((route) => {
          const currentRouteData = routeData.find((r) => r.id === route.id);
          const angularTowersCount = currentRouteData
            ? currentRouteData.towerTypes.reduce((sum, type) => {
                return (
                  sum +
                  (route.tower_stats?.find((stat: { type: string }) => stat.type === type)?.count ||
                    0)
                );
              }, 0)
            : 0;

          return (
            <div key={route.id}>
              {/* <div className="highlighted">{angularTowersCount}</div> */}
              <div className="highlighted">{angularTowersCount}</div>
              <div>&nbsp;</div>
              {allTowerTypes.map((type) => (
                <div key={type}>
                  {route.tower_stats?.find((stat: { type: string }) => stat.type === type)?.count ||
                    0}
                </div>
              ))}
              <div className="highlighted">{formatIndianNumber(route.total_tower_cost || 0)}</div>

              <div>&nbsp;</div>
              {dynamicHeaders.linearCounts.map((line, index) => (
                <div key={index}>
                  {route.linear_count?.find(
                    (l: { file_cat_name: string }) => l.file_cat_name === line
                  )?.count || 0}
                </div>
              ))}
              <div>&nbsp;</div>
              {dynamicHeaders.areaCrossStats.map((stat, index) => (
                <div key={index}>
                  {(
                    route.area_cross_stat?.find(
                      (s: { file_cat_name: string }) => s.file_cat_name === stat
                    )?.area_km_sq || 0
                  ).toFixed(2)}
                </div>
              ))}

              <div className="highlighted">{route.total_row_cost || '-'}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RouteTab;
