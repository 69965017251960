import React from 'react';
import { sortBy } from 'lodash';
import { Table, Typography } from 'antd';
import { RouteDetail } from 'store/route/types';

const { Text } = Typography;

interface RouteWeightBufferDetailPropsType {
  title: string;
  selectedRouteData?: RouteDetail;
}

const RouteWeightBufferDetail = ({
  title,
  selectedRouteData
}: RouteWeightBufferDetailPropsType) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'file_cat',
      key: 'file_cat'
    },
    {
      title: 'Buffer (m)',
      dataIndex: 'buffer',
      key: 'buffer'
    },
    {
      title: 'Weights',
      dataIndex: 'weight',
      key: 'weight'
    }
  ];
  return (
    <div>
      <div className="aoi_card">
        <Text className="aoi_card_title">{title}</Text>
        <Table
          scroll={{ y: '200px' }}
          className="aoi-card-table"
          dataSource={sortBy(selectedRouteData?.parameters?.vectors, ['file_cat'])}
          pagination={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

RouteWeightBufferDetail.defaultProps = {
  selectedRouteData: {}
};

export default RouteWeightBufferDetail;
