import { useEffect, useRef, useState } from 'react';
import { find, indexOf, isEmpty, orderBy } from 'lodash';
import { Modal, Space, Spin, Select as AntdSelect, Typography } from 'antd';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString, Point } from 'ol/geom';
import Feature from 'ol/Feature';
import { Style, Stroke, Fill } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { AiOutlineLoading } from 'react-icons/ai';
import CircleStyle from 'ol/style/Circle';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import OLPopup from 'ol-popup';
import { white } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { RouteDetail, RouteState, TowerType } from 'store/route/types';
import { editRouteTowerType, getRouteLists } from 'store/route/actions';
import { getTowerTypeOptions } from 'store/route';
import { ProjectState } from 'store/projects/types';
import { CoordinateType } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { useMap } from './MapContext';

const { Text } = Typography;

interface RouteLayerComponentProps {
  selectedRouteIds: string[];
  routeLists: RouteDetail[] | [];
  editedRouteData: RouteDetail | null;
}

const RouteLayerComponent = ({
  routeLists,
  selectedRouteIds,
  editedRouteData
}: RouteLayerComponentProps) => {
  const map = useMap();
  const dispatch = useAppDispatch();
  const towerOptions = useAppSelector((state) => getTowerTypeOptions(state));
  const { towerTypes }: RouteState = useAppSelector((state) => state.route);
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();

  // const overlayRef = useRef<Overlay>();
  const popupRef = useRef<OLPopup>();
  const selectInteractionRef = useRef<Select>();
  const [isUpdateTypeLoading, setUpdateTypeLoading] = useState<boolean>(false);
  const [isEditRoutePointModal, setEditRoutePointModal] = useState<boolean>(false);
  const [towerData, setTowerData] = useState<any | null>(null);
  const [towerType, setTowertype] = useState<number | null>(null);
  const [towerId, setTowerId] = useState<number | null>(null);

  useEffect(() => {
    if (vectorLayerRef.current && map && isEmpty(selectedRouteIds)) {
      map.removeLayer(vectorLayerRef.current);
    }
  }, [selectedRouteIds]);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 100,
      style: (feature) => {
        const routeColor = feature.get('color');
        return new Style({
          stroke: new Stroke({
            color: routeColor,
            width: 2
          }),
          zIndex: 100
        });
      }
    });
    vectorLayerRef.current.set('name', 'routelist');
    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
        popupRef.current?.hide();
        selectInteractionRef.current?.getFeatures().clear();
      }
    };
  }, []);

  useEffect(() => {
    if (!vectorSourceRef.current || !routeLists || !map) return;
    vectorSourceRef.current.clear();
    // eslint-disable-next-line
    routeLists.forEach((route: RouteDetail) => {
      if (
        route.geometry &&
        route.geometry.type === CoordinateType.LineString &&
        selectedRouteIds.includes(route.id) &&
        !isEmpty(route.geometry.coordinates)
      ) {
        const routeIndex: number = indexOf(selectedRouteIds, route.id);
        if (editedRouteData === null) {
          orderBy(route.towers, 'id').forEach((tower: TowerType, j: number) => {
            const routePointFeature = new Feature({
              geometry: new Point(fromLonLat(tower.geometry.coordinates)),
              color: route.color,
              type: tower.geometry.type
            });
            routePointFeature.setStyle(
              new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({ color: route.color }),
                  stroke: new Stroke({ color: route.color })
                }),
                zIndex: routeIndex + 100
              })
            );
            routePointFeature.setId(tower.id);
            routePointFeature.set(
              'name',
              `${tower.type}-${Number(tower.deviation_angle).toFixed(2)}`
            );
            routePointFeature.set('index', j);
            vectorSourceRef.current?.addFeature(routePointFeature);
          });
        }

        const select = new Select({
          condition: click,
          filter: (feature) => feature.get('type') === 'Point'
        });

        map.addInteraction(select);
        selectInteractionRef.current = select;

        select.on('select', (event) => {
          const selectedFeature = event.selected[0];

          if (selectedFeature) {
            // @ts-ignore
            const coordinates = selectedFeature.getGeometry().getCoordinates();
            const selectedTowerId = selectedFeature.getId();

            const name = selectedFeature.get('name');
            if (popupRef.current && editedRouteData === null) {
              popupRef.current.show(
                coordinates,
                `<div class='route-point'>
                    <div class='route-ol-container'>
                        <div>
                            <span>Deviation Angle: <b>${name.split('-')[1]}</b></span>
                            <span>Tower Type: <b>${name.split('-')[0]}</b></span>
                        </div>
                        <button id='routeeditbutton'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57725 11.9053L6.81 11.702L11.01 7.49828L8.988 5.47553L4.77525 9.68828L4.57725 11.9053ZM9.993 4.47128L12.0142 6.49253L13.4745 5.03003L11.454 3.00953L9.993 4.47128ZM3.21978 13.2643C3.06228 13.1068 2.98353 12.8878 3.00303 12.6658L3.28728 9.53828C3.31878 9.19553 3.47028 8.87228 3.71478 8.62778L10.4618 1.88078C10.9883 1.35203 11.943 1.37828 12.4988 1.93253L14.5523 3.98603L14.553 3.98678C15.1268 4.56128 15.15 5.47478 14.604 6.02228L7.85628 12.77C7.61253 13.0138 7.28928 13.1653 6.94578 13.1968L3.81828 13.481C3.79578 13.4825 3.77328 13.4833 3.75003 13.4833C3.55278 13.4833 3.36153 13.4053 3.21978 13.2643ZM15 15.7333C15 16.1458 14.6625 16.4833 14.25 16.4833H3.75C3.33825 16.4833 3 16.1458 3 15.7333C3 15.3215 3.33825 14.9833 3.75 14.9833H14.25C14.6625 14.9833 15 15.3215 15 15.7333Z"
                                fill="#696B72" />
                            </svg>
                        </button>
                        <button id='routeclosebutton'>
                            x
                        </button>
                    </div>
                </div>`
              );

              const editBtn = document.getElementById('routeeditbutton');
              const closeBtn = document.getElementById('routeclosebutton');

              if (editBtn) {
                editBtn.addEventListener('click', () => {
                  setEditRoutePointModal(true);
                  setTowertype(name.split('-')[0]);
                  setTowerId(Number(selectedTowerId));
                  setTowerData({
                    id: selectedTowerId,
                    deviation_angle: name.split('-')[1],
                    type: name.split('-')[0]
                  });
                });
              }
              if (closeBtn) {
                closeBtn.addEventListener('click', () => {
                  popupRef.current?.hide();
                  selectInteractionRef.current?.getFeatures().clear();
                });
              }
            }
          }
        });

        const coords = orderBy(route.towers, 'id').map((t: TowerType) =>
          fromLonLat(t.geometry.coordinates)
        );
        const feature = new Feature({
          geometry: new LineString(coords),
          color: route.color
        });
        feature.setId(route.id);
        feature.setStyle(
          new Style({
            stroke: new Stroke({
              color: route.color,
              width: 2
            }),
            zIndex: routeIndex + 100
          })
        );
        vectorSourceRef.current?.addFeature(feature);

        return () => {
          map.removeInteraction(select);
        };
      }
    });
  }, [routeLists, selectedRouteIds, editedRouteData]);

  useEffect(() => {
    if (map) {
      popupRef.current = new OLPopup();
      map.addOverlay(popupRef.current);
    }
    return () => {
      if (map && popupRef.current) {
        map.removeOverlay(popupRef.current);
      }
    };
  }, [map]);

  const handleSelectTowerType = (val: number) => {
    setTowertype(val);
  };

  const handleUpdateTowerType = () => {
    setUpdateTypeLoading(true);
    if (towerId && towerType) {
      const towerData = find(towerTypes, ['id', towerType]);
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          editRouteTowerType(
            { type_name: towerData?.name!, type_id: towerType },
            towerId,
            token,
            (status: boolean) => {
              if (status) {
                if (selectedProject) {
                  dispatch(
                    getRouteLists(selectedProject.id, token, () => {
                      setEditRoutePointModal(false);
                      setUpdateTypeLoading(false);
                      if (popupRef.current) {
                        popupRef.current.hide();
                      }
                    })
                  );
                }
                return;
              }
              setUpdateTypeLoading(false);
            }
          )
        );
      });
    }
  };

  return (
    <>
      <Spin
        spinning={isUpdateTypeLoading}
        style={{
          width: '100%',
          position: 'absolute',
          top: '50%'
        }}
        indicator={<AiOutlineLoading className="spin-loader" speed={500} color={white} />}
      />
      <Modal
        open={isEditRoutePointModal}
        title=""
        closable={false}
        centered
        width={245}
        maskClosable={false}
        className="edit-route-modal"
        okText="Update"
        okButtonProps={{ type: 'text', loading: isUpdateTypeLoading }}
        cancelButtonProps={{ type: 'text', disabled: isUpdateTypeLoading }}
        onCancel={() => setEditRoutePointModal(false)}
        onOk={handleUpdateTowerType}>
        <div className="edit-tower-container">
          <div>
            <Text className="point-title">Deviation Angle</Text>
            <Space className="deviation-angle">{towerData ? towerData.deviation_angle : '-'}</Space>
          </div>
          <div>
            <Text className="point-title">Tower Type</Text>
            <AntdSelect
              showArrow
              showSearch
              className="tower-type"
              onChange={handleSelectTowerType}
              value={towerType}
              options={towerOptions}
              placeholder="Select Type"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RouteLayerComponent;
