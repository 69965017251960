import { useMemo } from 'react';
import { useAppSelector } from 'hooks/useReduxHook';
import { find } from 'lodash';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getAOILayersGroupByCategory } from 'store/repositoryfile';
import { ProjectStagesList, ProjectState, ProposedTimelineStatus } from 'store/projects/types';
import { AOIDetail, AoiState } from 'store/aoi/types';
import LayerList from './LayerList';
import AOIList from './AOIList';

interface AoiGenerationStepProps {
  isRouteGeneration: boolean;
  isDisableGenerateButton: boolean;
  isShowGenerateButton: boolean;
  isShowHideWeightSlider: boolean;
  selectedAoiIds: string[];
  vectorLayerCategories: string[];
  layers: Array<RepositoryFile> | [];
  layerWeight: number;
  selectedLayer: number | null;
  editedAOIData: AOIDetail | null;
  selectedAOIData: AOIDetail | null;
  handleChangeLayerPosition: (layer: number, layerPos: number) => void;
  handleFileWeightChange: (val: number) => void;
  handleAfterFileWeightChange: (val: number, file: RepositoryFile[]) => void;
  handleVectorLayerIds: (category: string) => void;
  handleShowHideLayers: (isshowhide: boolean) => void;
  handleShowHideSelectedAoiIds: (fileId: string) => void;
  handleGenerateAoiData: () => void;
  handleHideSlider: () => void;
  handleSelectedAOI: (data: AOIDetail | null) => void;
  handleEditAOI: (data: AOIDetail | null) => void;
}

const AoiGenerationStep = ({
  isRouteGeneration,
  isDisableGenerateButton,
  isShowGenerateButton,
  isShowHideWeightSlider,
  layers,
  layerWeight,
  selectedLayer,
  selectedAoiIds,
  editedAOIData,
  selectedAOIData,
  vectorLayerCategories,
  handleChangeLayerPosition,
  handleFileWeightChange,
  handleVectorLayerIds,
  handleShowHideLayers,
  handleAfterFileWeightChange,
  handleShowHideSelectedAoiIds,
  handleGenerateAoiData,
  handleSelectedAOI,
  handleEditAOI,
  handleHideSlider
}: AoiGenerationStepProps) => {
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const { isRequestingGenerateAOI, aoiLists }: AoiState = useAppSelector((state) => state.aoi);
  const layerCategory = useAppSelector((state) => getAOILayersGroupByCategory(state));

  const isHighResLULCGeneration = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.LULCHigh,
      status: ProposedTimelineStatus.Completed
    })
  );

  const layerList = useMemo(
    () => (
      <LayerList
        isRouteGeneration={isRouteGeneration}
        selectedLayer={selectedLayer}
        layerWeight={layerWeight}
        layers={layers}
        layerCategory={layerCategory}
        vectorLayerCategories={vectorLayerCategories}
        isShowHideWeightSlider={isShowHideWeightSlider}
        handleChangeLayerPosition={handleChangeLayerPosition}
        handleVectorLayerIds={handleVectorLayerIds}
        handleShowHideLayers={handleShowHideLayers}
        handleFileWeightChange={handleFileWeightChange}
        handleAfterFileWeightChange={handleAfterFileWeightChange}
        handleEditBuffer={() => {}}
        handleHideSlider={handleHideSlider}
      />
    ),
    [
      layers,
      layerWeight,
      layerCategory,
      selectedLayer,
      vectorLayerCategories,
      isShowHideWeightSlider
    ]
  );

  return (
    <div
      className={`main-start-end-point aoi-layers ${
        isHighResLULCGeneration ? 'complete-aoi' : ''
      }`}>
      {layerList}
      {/* AOI generation and list and view comment modal START */}
      <AOIList
        key="aoilist"
        isDisableGenerateButton={isDisableGenerateButton}
        isShowGenerateButton={isShowGenerateButton}
        isRequestingGenerateAOI={isRequestingGenerateAOI}
        aoiLists={aoiLists}
        editedAOIData={editedAOIData}
        selectedAOIData={selectedAOIData}
        selectedAoiIds={selectedAoiIds}
        handleGenerateAoiData={handleGenerateAoiData}
        handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
        handleEditAOI={handleEditAOI}
        handleSelectedAOI={handleSelectedAOI}
      />
      {/* AOI generation and list and view comment modal END */}
    </div>
  );
};

export default AoiGenerationStep;
